/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

global.$ = global.jQuery = require('jquery');

// npm dependencies
import 'jquery-ui-bundle'
import 'bootstrap'

// resources dependecies
import './vendors/vendors'
import '../pdait/pdait'
import './mdb/mdb.min'


//import './mdb/mdb.min'
import './theme'
